import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import tw from 'twin.macro';
import { CopyStyle } from './UI';
import Spinner from './Spinner';
const GOOGLE_SHEET_URL = process.env.GATSBY_GOOGLE_SHEET_STORAGE_URL;

const HubSpotHealthcareForm = () => {
  const [submitting, setFormSubmiting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const form = useForm();
  const formRef = useRef();
  const onSubmit = async (data) => {
    setFormSubmiting(true);
    const params = {
      ...data,
      sheetName: 'Book a demo'
    }

    const formData = new FormData();
    // To make form data of the form object
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key])
    })

    const options = {
      method: 'POST',
      body: formData,
    }

    try {
      const submitUrl = GOOGLE_SHEET_URL;
      const response = await fetch(submitUrl, options);
      const json = await response.json();
      console.log(json);
      if (json.result === 'success') {
        alert('Form submitted successfully');
        formRef.current.reset();
        setSubmitted(true);
      }
    } catch (e) {
      console.log('Error submitting form', e);
      alert('Error submitting form: ', e);
    } finally {
      setFormSubmiting(false);
    }
  }

  return (
    <div className='form-wrapper'>
      <form ref={formRef} className='hs-form' onSubmit={form.handleSubmit(onSubmit)}>
        <div tw='grid grid-cols-12 gap-4'>
          <div tw='col-span-6'>
            <input className='hs-input' type='text' name='firstname' placeholder='First name*' required {...form.register('firstName', { required: true })} />
            {form.formState.errors.firstName && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-6'>
            <input className='hs-input' type='text' name='lastname' placeholder='Last name*' required {...form.register('lastName', { required: true })} />
            {form.formState.errors.lastName && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-6'>
            <input className='hs-input' type='text' name='email' placeholder='Email*' required {...form.register('email', { required: true })} />
            {form.formState.errors.email && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-6'>
            <input className='hs-input' type='text' name='phone' placeholder='Phone Number*' required {...form.register('phone', { required: true })} />
            {form.formState.errors.phone && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-6'>
            <input className='hs-input' type='text' name='company' placeholder='Company Name*' required {...form.register('company', { required: true })} />
            {form.formState.errors.company && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-6'>
            <select className='hs-input' required name="facilityType" {...form.register('facilityType', { required: true })}>
              <option disabled value="">Facility Type*</option>
              <option value="Hospital">Hospital</option>
              <option value="Long Term Care">Long Term Care</option>
              <option value="Retirement Home">Retirement Home</option>
              <option value="Home Care">Home Care</option>
              <option value="Hospice">Hospice</option>
              <option value="Skilled Nursing">Skilled Nursing</option>
              <option value="Assisted Living">Assisted Living</option>
              <option value="Independent Living">Independent Living</option>
              <option value="Agency">Agency</option>
            </select>
            {form.formState.errors.facilityType && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-12 mt-2'>
            <textarea className='hs-address' name='streetAddress' placeholder='Street Address*' {...form.register('streetAddress', { required: true })}></textarea>
            {form.formState.errors.streetAddress && (
              <span css={[CopyStyle, tw`text-red-500`]}>
                This field is required
              </span>
            )}
          </div>
          <div tw='col-span-12 mt-2 text-center'>
            <button className='hs-button primary large' type='submit' disabled={submitting}>
              {submitting ? <Spinner color={'#FFF'} /> : 'Request a demo'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HubSpotHealthcareForm;
